@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
html{
    scroll-behavior: smooth;
}
body{
  overflow-x: hidden;
}
#root{
  overflow: hidden;
  position: relative
}
.br{
  border:4px solid red;
}
.bw{
  border:4px solid white
}

