.services-box-shadow {
    box-shadow: 0px 3px 6px #00000029;
}

@media screen and (min-width:640px) {
    .serviceCard {
        width: 21vw;
        height: 16vw;
       
    }
}
@media screen and (max-width:640px) {
    .serviceCard {
        width: 40vw;
        height: 35vw;
       
    }
}
