.img-box-shadow {
    -webkit-box-shadow: 10px 10px 19px -17px rgba(0, 0, 0, 1);
    -moz-box-shadow: 10px 10px 19px -17px rgba(0, 0, 0, 1);
    box-shadow: 10px 10px 19px -17px rgba(0, 0, 0, 1);
}

.company-name {
    max-width: 60vw !important;
}


@media screen and (max-width:640px) {
    .company-name {
        max-width: 40vw;
    }

    .portfolio-tags {
        width: 40vw;

    }

    .tag-card {
        width: auto;
        height: 5vw;
        font-size: 2.5vw;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    }
}

/* max-w-[40vw] sm:max-w-0 */