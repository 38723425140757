.hero-gradient {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFF7E6 10%, #FFE9B6 28%, #FFE39F 83%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
}

.hero-subgradient {
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1;
    
}

.colab-card {
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 14px;
    opacity: 1;
}

.hero-box-shadow {
    box-shadow: 0px 3px 6px #00000029;
}

.plane{
    offset-path: path("M 600 400 ");
    animation: move 4s linear ;
    
}
.hamburger-temp{
    right: 4.5vw;
  
    
}
.logo-hamburger-temp{
    bottom:4vw;
    right: 4vw;
    z-index: 999999;
}

.bounce-text span{
    animation: waviy 1s infinite;
    animation-delay: calc(.1s * var(--i));
}
@keyframes waviy {
    0%,40%,100% {
      transform: translateY(0)
    }
    20% {
      transform: translateY(-20px)
    }
  }
@keyframes move {
    100% { 
     
      offset-distance: 100%;
    }
  }
  @media screen and (max-width:640px){
    .colab-card {
        background: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 1vw;
        opacity: 1;
    }
    .address-mob{
      position: absolute;
      left:-10vw !important
    }
  }