.testimonial-gradient {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFF7E6 10%, #FFE9B6 28%, #FFE39F 83%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 0.99;
}

.swiper {
  width: 100vw;
  height: 30vw;
  padding-top: 50px;
  padding-bottom: 50px;



}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 200px;
}

.outer-testimonial {
  margin-left: 0.3vw;
}
@media screen and (min-width:640px){
  .outer-testimonial {
    margin-left: 1vw;
  }
}
.message-icon {
  width: 3vw;
  margin-bottom: 1vw;
}

.testimonial-message {
  font-size: 0.9vw;
}

.mySwiper {
  height: 30vw;
}

.review-icon {
  width: 3vw;
}

.testimonial-card {
  height: 18vw;
  width: 28vw
}

.founders {
  top: 15vw
}
.testimonial-image{
  display: grid;
  place-content: center;
  width: 5vw;
  color:white;
  height: 5vw;
  font-size: 1.2vw;
  border-radius: 50%;
  background-color: rgb(65, 64, 64);
}
@media screen and (max-width:640px) {
  .testimonial-image{
    display: grid;
    place-content: center;
    width: 15vw;
    color:white;
    height: 15vw;
    font-size: 4vw;
    border-radius: 50%;
    background-color: rgb(65, 64, 64);
  }
  .testimonial-message {
    max-width: 90vw;
  }

  .message-icon {
    width: 12vw;
  }

  .review-icon {
    width: 15vw;
    position: relative;
    right: 2vw;
    top: 1vw;
  }

  .testimonial-message {
    font-size: 4vw;
  }

  .mySwiper {
    height: 90vw;
  }

  .testimonial-card {
    width: 97vw;
    height: 60vw
  }

  .founders {
    top: 53vw
  }
}