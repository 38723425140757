.lets-connect-description {
    max-width: 40vw;
}
.lets-connect-heading {
    max-width: 30vw;
}

@media screen and (max-width:640px) {
    .lets-connect-section {
        flex-direction: column;
        text-align: center;

    }
.lets-connect-title{
    font-size: 6vw;
    position: relative;
    top: -8vw;
}
    .lets-connect-numbers {
        position: relative;
        left: 15vw;
        margin-top: 6vw;
    }

    .lets-connect-description {
        text-align: left;
        margin-top: 6vw;
       
        max-width: 80vw;
    }

    .lets-connect-description1 {
        line-height: 3.2vw;

    }

    .lets-connect-description2 {
        line-height: 3.2vw;
    }
    .lets-connect-heading {
        max-width: 80vw;
    }
    

}