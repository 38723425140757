.services-section{
    height:60vw
}

@media screen and (max-width:1000px){
    .services-section{
        height:93vw;
        grid-gap:4vw
        
    }
} 
@media screen and (max-width:640px){
    .services-section{
        height:200vw!important
    }
} 