.portfolio-profile-mob {
    margin-bottom: 15vw;
}
.portfolio-profile-mob-video{
    margin-top: 15vw;
}
/* .portfolio-cards img{
    width: 45vw;
    height: 26vw;
} */
@media screen and (max-width:640px) {
    .portfolio-heading {
        font-size: 3.4vw;
    }

    .portfolio-main-heading-mob {
        font-size: 5vw;
    }

    .portfolio-main-subheading-mob {
        font-size: 3vw
    }

    .portfolio-logo-mob {
        width: 25vw;
    }

    .portfolio-outside {
        position: relative;
        margin-top: 15vw;
    }

    .portfolio-profile-mob {
        margin-bottom: 15vw;
    }

    .portfolio-profile-logo-mob {
        width: 22vw;
    }
    .portfolio-profile-mob-video{
        margin-top: 15vw;
    }
}