@media screen and (max-width:640px) {

    .features-text {
        width: 100%;
        height:120vw;
        margin-top:15vw
    }
    .feature-heading{
        text-align: center;
        margin-top: 6vw;
    }
    .features{
        margin-top: 6vw;
    }
    .feature-text{
        font-size: 4vw;
        max-width: 90vw;
        margin-top: -1.2vw;
        margin-bottom: 5vw;
    }
    .features-heading{
        font-size: 5vw;
    }
}