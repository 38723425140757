.contact input {
    background-color: rgb(255, 255, 255);
    border-radius: 0.3vw;
    font-size: 0.8vw;
    padding: 0.5vw;
    
}
.contact input::placeholder{
    
    opacity: 0.7;
    font-style: 400;
}
.contact input:focus{
    outline:none;
    
    background-color: white;
   
    
    
}
@media screen and (max-width:640px){
    .contact input {       
        font-size: 2vw;
        padding: 1vw;        
    }
}