.question-input input {
  background-color: transparent;
  border-radius: 0.3vw;
  font-size: 1vw;
  padding: 1.2vw;
  color: white;
  border-color: rgb(187, 187, 187);
  ;
}

.question-input input::placeholder {
  color: rgb(187, 187, 187);
  opacity: 0.7;
  font-style: 400;
}

/*  To hide input numeric buttons */
.question-input input::-webkit-outer-spin-button,
.question-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.question-input input[type=number] {
  -moz-appearance: textfield;
}

.modal-z-index {
  z-index: 999999999999999999 !important;
}

.dropdown-button {
  width: 20vw;
  margin-top: 1vw;
  background-color: transparent;
  border-radius: 0.3vw;
  border-style: solid;
  font-size: 1vw;
  padding: 0.5vw 1.2vw;
  border: 0.1vw solid rgb(187, 187, 187);
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  border-radius: 0.3vw;
  width: 20vw;
  height: auto;
  margin-top: 1vw;
  background-color: rgb(245, 243, 243);
  top: 4.5vw;
  z-index: 999999999;
}

.dropdown-placeholder {
  color: rgb(187, 187, 187);
  opacity: 0.7;
  font-style: 400;
  font-weight: 300;
}

.chevronDown {
  position: absolute;
  top: -1vw;
  right: 1vw;
  width: 1vw;
}

.question {
  font-size: 0.8vw;
}

.question-outer-div {
  margin-top: 3vw;
}

.cross-position {
  right: 8vw;
  cursor: pointer;
}

@media screen and (max-width:640px) {
  .question-input input {
    background-color: transparent;
    border-radius: 0.3vw;
    font-size: 4vw;
    padding: 1.2vw;
    color: white;
    border-color: rgb(187, 187, 187);
    ;
  }

  .dropdown-button {
    width: 80vw;
    margin-top: 1vw;
    background-color: transparent;
    border-radius: 0.3vw;
    border-style: solid;
    font-size: 4vw;
    padding: 1.2vw;
    border: 0.1vw solid rgb(187, 187, 187);
    cursor: pointer;
  }


  .chevronDown {
    width: 4vw;
    right: 4vw;
  }

  .dropdown-menu {
    position: absolute;
    border-radius: 1vw;
    width: 80vw;
    height: auto;
    margin-top: 17vw;
    background-color: rgb(245, 243, 243);
    top: 5.5vw;
    z-index: 999999999;
  }

  .calendly-link {
    position: absolute;
    top: 64vw !important;
    left: 10vw
  }

  .calendly-link-submit {
    position: absolute;
    bottom: 3vw;
    left:5vw
  }

  .cross-position {
    top:50vw;
    right: 8vw;
    cursor: pointer;
  }
  
}

/* w-[80vw] h-[10vw] */