.footer-web {
    width: 100%;
    /* height: 30vw; */
    background-color: black;
}

.footer-web-outer-div {
    padding: 7vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.offiql-info {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.offiql-heading {
    display: flex;
    flex-direction: column;
}

.offiql-name,
.offiql-subheading,
.offiql-register,
.follow-us-heading {

    color: white;
    font-family: 'poppins';
}
.policies{
    font-family: 'poppins';
}

.offiql-name {
    font-size: 3vw;
    font-weight: 600;

}

.offiql-register {
    width: auto;
    font-size: 1.1vw;
    margin-top: 3px;
    font-weight: 400;
}

.offiql-subheading {
    width: auto;
    font-size: 1.2vw;

    font-weight: 400;
    margin-top: 1vw;
}

.follow-us-heading {
    font-size: 2vw;
    font-weight: 600;

}

.social-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    margin-top: 2vw;
}

.disclaimer,
.terms {
    color: rgb(114, 114, 114);
    font-size: 1.2vw;
    font-weight: 600;
    margin-top: 2vw;
}

.terms:hover {
    color: aliceblue;
    transition: all;
    transition-duration: 300;
    cursor: pointer;
}

.disclaimer:hover {
    color: aliceblue;
    transition: ease-in;
    transition-duration: 300;
    cursor: pointer;
}

.follow-us-col::after {
    content: '';
    display: block;
    width: 0.1vw;
    height: 15vw;
    background-color: white;
    position: absolute;
    top: 0;
    left: 14vw;
}

.offiql-address-info {
    margin-left: 4vw;

}

.registered-company {
    font-size: 1.4vw;
    color: white;
    font-weight: 600;
}

.address {
    color: white;
    font-size: 1.2vw;
    margin-top: 1vw;

}

.address-1,
.address-2 {
    margin-left: 1vw;
    color: white;
    font-size: 1.2vw;
    margin-top: 1.5vw;
}

.address-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.svg-height {
    width: 1.7vw;
    height: 1.7vw;
    margin-right: 1vw;
}

.svg-height-2 {
    width: 1.7vw;
    height: 1.7vw;
    margin-right: 0.1vw;
    margin-top: 1.5vw;
}