.background-footer {
    background: transparent linear-gradient(270deg, #FFD56F 0%, #FFD46B 0%, #FFCC52 45%, #FFB913 95%, #FFB811 100%) 0% 0% no-repeat padding-box;
}
.policies{
    font-family: 'poppins';
}

.search input {
    background-color: #8a8b8b;
    border-radius: 0.3vw;
    font-size: 1vw;
    padding: 1.5vw;
    color: white
}

.search input::placeholder {
    color: white;
    opacity: 0.7;
    font-style: 400;
}

.search input:focus {
    outline: none;
    background-color: #9da1a1;

}

.social-links{
    display: flex;
    justify-content: space-around;
}
@media screen and (max-width:640px) {


    .search input {
        font-size: 2.5vw;
    }


}

@media screen and (max-width:640px) {

    .aboutUs {
        font-size: 4vw !important;
    }

    .social-links{
        display: flex;
        justify-content: space-around;
        position: relative;
        left: -3vw;
    }
    .footer-subheading  {
        font-size: 3.3vw !important
    }
}

@media screen and (max-width:1440px) {

    .aboutUs {
        font-size: 2vw;
    }

    .footer-subheading  {
        font-size: 1.3vw
    }
}
.offiql-register {
    width: auto;
    font-size: 1.1vw;
    margin-top: 3px;
    color: white;
    font-family: 'poppins';
    font-weight: 400;
}
@media screen and (max-width:934px) {

   

    .footer-subheading  {
        font-size: 1.6vw
    }
}