nav ul li {
  list-style: none;
  margin: 35px 0;
}

nav ul li:nth-child(1) {
  position: relative;
  right: 12vw;
}

nav ul li:nth-child(2) {
  position: relative;
  right: 8vw;
}

nav ul li:nth-child(3) {
  position: relative;
  right: 7.4vw;
}

nav ul li:nth-child(4) {
  position: relative;
  right: 6vw;
}

nav ul li:nth-child(5) {
  position: relative;
  right: 12vw;
}

nav ul li a {
  text-decoration: none;
  font-size: 50px;
  padding: 10px;
  letter-spacing: 0px;
  position: relative;
  font-weight: 800;
  /* color:#999;
font-size:12px;
padding:5px 2px; */
}

.circle {
  z-index: 9999999;
}

.close-icon {
  position: absolute;
  width: 25px;
  left: 80px;
  top: 50px;
  cursor: pointer;
  z-index: 99999;
}

nav ul li a:after {

  content: "";
  height: 3px;
  width: 0;
  background: #eea16d;
  position: absolute;
  bottom: 0;
  left: 0;
}

nav ul li a:hover::after {
  width: 100%;
  transition: width 0.5s;
}

nav {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: black;
  z-index: 9000000 !important;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 1s;
  overflow: hidden;
}

.burger-menu {
  position: relative;
  right: -1vw;
  z-index: 99999 !important;
}

.big-fat-orange-circle {

  transition: 1s;
  right: -30vw;
  position: absolute;
  width: 50vw;
  height: 50vw;
  border-radius: 50%;
  background: rgb(255, 124, 1);
}

.burger {
  z-index: 99999 !important;

}

@media screen and (max-width:640px) {
  nav ul li:nth-child(1) {
    position: relative;
    right: 6vw;
    top:-6vw
  }
  nav{
    z-index: 99999999999999999!important;
  }
  nav ul li a{
    font-size: 8vw;
  }
  nav ul li:nth-child(2) {
    position: relative;
    right: -5vw;
    top: -10vw;
  }

  nav ul li:nth-child(3) {
    position: relative;
    right: -5.4vw;
    top: -13vw;
  }

  nav ul li:nth-child(4) {
    position: relative;
    right: -1vw;
    top:-15vw
  }

  nav ul li:nth-child(5) {
    position: relative;
    right: -9vw;
    top:-20vw
  }

  .big-fat-orange-circle {

    transition: 1s;
    right: -30vw;
    position: absolute;
    width: 350vw;
    height: 350vw;
    border-radius: 50%;
    background: rgb(255, 124, 1);
  }

  .logo-hamburger-temp {
    bottom: 16vw;
    right: 8vw;
    z-index: 999999;
    font-size: 8vw;
  }


}